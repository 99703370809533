import { createSlice } from '@reduxjs/toolkit'
import { getWorkflows, saveWorkflow, createWorkflow, deleteWorkflow } from './workflowActions'

const initialState = {
  workflows: [],
  loading: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedWorkflow: {},
  deleting: false,
  deleted: false,
  deletingError: false,
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const workflowsSlice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedWorkflow = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getWorkflows.fulfilled, (state, { payload }) => {
        state.loading = false
        state.workflows = payload
      })
      .addCase(getWorkflows.pending, (state) => {
        state.loading = true
        state.loadingError = null
        state.savingError = null
      })
      .addCase(getWorkflows.rejected, (state, { payload }) => {
        state.loading = false
        state.loadingError = payload
      })
      .addCase(saveWorkflow.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(saveWorkflow.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedWorkflow = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveWorkflow.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createWorkflow.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createWorkflow.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedWorkflow = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createWorkflow.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteWorkflow.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteWorkflow.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteWorkflow.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
    /*.addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
        },
      )*/
  },
})

export const { clear } = workflowsSlice.actions
export default workflowsSlice.reducer
