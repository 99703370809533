import { createSlice } from '@reduxjs/toolkit'
import {
  getExpressPayments,
  saveExpressPayment,
  createExpressPayment,
  deleteExpressPayment,
} from './expressPaymentActions'

const initialState = {
  expressPayments: [],
  loading: false,
  error: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedExpressPayment: {},
  deleting: false,
  deleted: false,
  deletingError: false,
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const expressPaymentsSlice = createSlice({
  name: 'expressPayments',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedExpressPayment = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getExpressPayments.fulfilled, (state, { payload }) => {
        state.loading = false
        state.expressPayments = payload
      })
      .addCase(getExpressPayments.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(saveExpressPayment.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(saveExpressPayment.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.expressPayments[state.expressPayments.findIndex((p) => p.id === payload.id)] = payload
        state.savedPayment = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveExpressPayment.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createExpressPayment.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createExpressPayment.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedExpressPayment = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createExpressPayment.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteExpressPayment.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteExpressPayment.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteExpressPayment.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
      .addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
        },
      )
  },
})

export const { clear } = expressPaymentsSlice.actions
export default expressPaymentsSlice.reducer
