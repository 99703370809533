import { createSlice } from '@reduxjs/toolkit'
import { getPayments, savePayment, createPayment, deletePayment } from './paymentActions'

const initialState = {
  payments: [],
  loading: false,
  error: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedPayment: {},
  deleting: false,
  deleted: false,
  deletingError: false,
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedPayment = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getPayments.fulfilled, (state, { payload }) => {
        state.loading = false
        state.payments = payload
      })
      .addCase(getPayments.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(savePayment.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(savePayment.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.payments[state.payments.findIndex((p) => p.id === payload.id)] = payload
        state.savedPayment = payload
        state.saved = true
        state.saving = false
      })
      .addCase(savePayment.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createPayment.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createPayment.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedPayment = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createPayment.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deletePayment.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deletePayment.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deletePayment.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
      .addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
        },
      )
  },
})

export const { clear } = paymentsSlice.actions
export default paymentsSlice.reducer
