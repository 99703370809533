import { createSlice } from '@reduxjs/toolkit'
import {
  getPaymentConditions,
  getPaymentCondition,
  getPaymentConditionByProject,
  createPaymentCondition,
  savePaymentCondition,
} from './paymentConditionActions'

const initialState = {
  paymentConditions: [],
  paymentConditionsByProject: [],
  loading: false,
  error: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedPaymentCondition: {},
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const paymentConditionsSlice = createSlice({
  name: 'paymentConditions',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedPaymentCondition = {}
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getPaymentConditions.fulfilled, (state, { payload }) => {
        state.loading = false
        state.paymentConditions = payload
      })
      .addCase(getPaymentConditions.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getPaymentCondition.fulfilled, (state, { payload }) => {
        if (!state.paymentConditions.find((o) => o.id === payload.id)) {
          state.paymentConditions.push(payload)
        }
        state.loading = false
        state.error = null
      })
      .addCase(getPaymentCondition.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getPaymentConditionByProject.fulfilled, (state, { payload }) => {
        state.paymentConditionsByProject = payload
        state.loading = false
        state.error = null
      })
      .addCase(getPaymentConditionByProject.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(savePaymentCondition.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(savePaymentCondition.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.paymentConditions[state.paymentConditions.findIndex((p) => p.id === payload.id)] =
          payload
        state.savedPaymentCondition = payload
        state.saved = true
        state.saving = false
      })
      .addCase(savePaymentCondition.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createPaymentCondition.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createPaymentCondition.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedPaymentCondition = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createPaymentCondition.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
        },
      )
  },
})

export const { clear } = paymentConditionsSlice.actions
export default paymentConditionsSlice.reducer
