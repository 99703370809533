import { createSlice } from '@reduxjs/toolkit'
import { getApproves, saveApprove, createApprove, deleteApprove } from './approveActions'

const initialState = {
  approves: [],
  approvesObj: {},
  loading: false,
  error: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedApprove: {},
  deleting: false,
  deleted: false,
  deletingError: false,
  loaded: false,
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const approvesSlice = createSlice({
  name: 'approves',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedApprove = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
      state.loaded = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getApproves.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loaded = true
        state.approves = payload
        state.approvesObj = payload.reduce((a, v) => ({ ...a, [v.id]: v }), {})
      })
      .addCase(getApproves.pending, (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
      })
      .addCase(saveApprove.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(saveApprove.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.approves[state.approves.findIndex((p) => p.id === payload.id)] = payload
        state.savedApprove = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveApprove.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createApprove.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createApprove.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedApprove = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createApprove.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteApprove.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteApprove.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteApprove.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
      .addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
          state.loaded = false
        },
      )
  },
})

export const { clear } = approvesSlice.actions
export default approvesSlice.reducer
