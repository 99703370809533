import { createSlice } from '@reduxjs/toolkit'
import { getProjects, saveProject, createProject, deleteProject } from './projectActions'

const initialState = {
  projects: [],
  loading: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedProject: {},
  deleting: false,
  deleted: false,
  deletingError: false,
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedProject = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        state.loading = false
        state.projects = payload
        /*payload.forEach((i) => {
          state.projects[i.id] = i
        })*/
      })
      .addCase(getProjects.pending, (state) => {
        state.loading = true
        state.loadingError = null
        state.savingError = null
      })
      .addCase(getProjects.rejected, (state, { payload }) => {
        state.loading = false
        state.loadingError = payload
      })
      .addCase(saveProject.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(saveProject.fulfilled, (state, { payload }) => {
        state.savingError = null
        //state.projects[state.projects.findIndex((p) => p.id === payload.id)] = payload
        state.savedProject = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveProject.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createProject.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createProject.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.projects[payload.id] = payload
        state.savedProject = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createProject.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteProject.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteProject.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteProject.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
    /*.addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
        },
      )*/
  },
})

export const { clear } = projectsSlice.actions
export default projectsSlice.reducer
