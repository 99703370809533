import { createSlice } from '@reduxjs/toolkit'
import { getBonuses, saveBonus, createBonus, deleteBonus, deleteBonusPayment } from './bonusActions'

const initialState = {
  bonuses: [],
  loading: false,
  error: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedBonus: {},
  deleting: false,
  deleted: false,
  deletingError: false,
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const bonusesSlice = createSlice({
  name: 'bonuses',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedBonus = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getBonuses.fulfilled, (state, { payload }) => {
        state.loading = false
        state.bonuses = payload
      })
      .addCase(getBonuses.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(saveBonus.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(saveBonus.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.bonuses[state.bonuses.findIndex((p) => p.id === payload.id)] = payload
        state.savedBonus = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveBonus.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createBonus.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createBonus.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedBonus = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createBonus.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteBonus.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteBonus.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteBonus.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
      .addCase(deleteBonusPayment.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteBonusPayment.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteBonusPayment.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
      .addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
        },
      )
  },
})

export const { clear } = bonusesSlice.actions
export default bonusesSlice.reducer
