import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getDemands = createAsyncThunk('demand/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/demands/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveDemand = createAsyncThunk('demand/save', async (demand, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.put(`/api/demands/${demand.id}/`, demand)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const createDemand = createAsyncThunk(
  'demand/create',
  async (demand, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.post('/api/demands/', demand)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteDemand = createAsyncThunk(
  'demand/delete',
  async (demand, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.delete(`/api/demands/${demand.id}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
