import { createSlice } from '@reduxjs/toolkit'
import { getWorkTzs, saveWorkTz, createWorkTz, deleteWorkTz } from './workTzActions'

const initialState = {
  workTzs: [],
  loading: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedWorkTz: {},
  deleting: false,
  deleted: false,
  deletingError: false,
}

const workTzSlice = createSlice({
  name: 'workTzs',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedWorkTz = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getWorkTzs.fulfilled, (state, { payload }) => {
        state.loading = false
        state.workTzs = payload
        /*payload.forEach((i) => {
          state.workTzs[i.id] = i
        })*/
      })
      .addCase(getWorkTzs.pending, (state) => {
        state.loading = true
        state.loadingError = null
        state.savingError = null
      })
      .addCase(getWorkTzs.rejected, (state, { payload }) => {
        state.loading = false
        state.loadingError = payload
      })
      .addCase(saveWorkTz.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(saveWorkTz.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedWorkTz = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveWorkTz.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createWorkTz.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createWorkTz.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedWorkTz = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createWorkTz.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteWorkTz.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteWorkTz.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteWorkTz.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
    /*.addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
        },
      )*/
  },
})

export const { clear } = workTzSlice.actions
export default workTzSlice.reducer
