import { createSlice } from '@reduxjs/toolkit'
import { getTzs, saveTz, createTz, deleteTz } from './tzActions'

const initialState = {
  tzs: [],
  loading: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedTz: {},
  deleting: false,
  deleted: false,
  deletingError: false,
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const tzSlice = createSlice({
  name: 'tzs',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedTz = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getTzs.fulfilled, (state, { payload }) => {
        state.loading = false
        state.tzs = payload
        /*payload.forEach((i) => {
          state.tzs[i.id] = i
        })*/
      })
      .addCase(getTzs.pending, (state) => {
        state.loading = true
        state.loadingError = null
        state.savingError = null
      })
      .addCase(getTzs.rejected, (state, { payload }) => {
        state.loading = false
        state.loadingError = payload
      })
      .addCase(saveTz.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(saveTz.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedTz = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveTz.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createTz.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createTz.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedTz = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createTz.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteTz.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteTz.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteTz.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
    /*.addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
        },
      )*/
  },
})

export const { clear } = tzSlice.actions
export default tzSlice.reducer
