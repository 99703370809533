import axios from 'axios'
import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const userLogin = createAsyncThunk(
  'user/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const { data } = await axios.post('/user/api/token/', { email, password }, config)
      // store user's token in local storage
      // localStorage.setItem('authTokens', data)
      return data
    } catch (error) {
      localStorage.removeItem('authTokens') // deletes token from storage
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const userGet = createAsyncThunk('user/get', async (userId, { rejectWithValue }) => {
  try {
    const ax = useAxios()
    const { data } = await ax.get(`/user/api/users/${userId}/`)
    return data
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const getUsers = createAsyncThunk('users/get', async (...args) => {
  try {
    const ax = useAxios()
    const { data } = await ax.get(`/user/api/users/`)
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})
