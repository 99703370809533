import { createSlice } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

import { userLogin, userGet, getUsers } from './userActions'

// initialize userToken from local storage
const authTokens = localStorage.getItem('authTokens')
  ? JSON.parse(localStorage.getItem('authTokens'))
  : null

const initialState = {
  user: {
    loading: false,
    error: null,
    users: [],
  },
  currentUser: !authTokens ? {} : jwtDecode(authTokens.access),
  approvesCheckCount: 0,
  loading: false,
  loaded: false,
  authTokens, // for JWT tokens
  error: null,
  success: false, // for monitoring the registration process.
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('authTokens') // deletes token from storage
      state.loading = false
      state.authTokens = null
      state.error = null
      state.currentUser = {}
    },
    setTokens: (state, { payload }) => {
      state.authTokens = payload
      localStorage.setItem('authTokens', JSON.stringify(payload))
      state.error = null
      state.loading = false
    },
    setApprovesCheckCount: (state, { payload }) => {
      state.approvesCheckCount = payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true
        state.error = null
        state.currentUser = {}
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        localStorage.setItem('authTokens', JSON.stringify(payload))
        state.loading = false
        state.authTokens = payload
        state.error = null
        state.currentUser = jwtDecode(payload.access)
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.currentUser = {}
      })
      .addCase(userGet.pending, (state) => {
        state.user.loading = true
        state.user.error = null
      })
      .addCase(userGet.fulfilled, (state, { payload }) => {
        if (!state.user.users.find((u) => u.id === payload.id)) {
          state.user.users.push(payload)
        }
        state.user.loading = false
        state.user.error = null
      })
      .addCase(userGet.rejected, (state, { payload }) => {
        state.user.loading = false
        state.user.error = payload
      })
      .addCase(getUsers.pending, (state) => {
        state.user.loading = true
        state.user.error = null
        state.loaded = false
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.user.users = payload
        state.user.loading = false
        state.user.error = null
        state.loaded = true
      })
      .addCase(getUsers.rejected, (state, { payload }) => {
        state.user.loading = false
        state.user.error = payload
        state.loaded = false
      })
  },
})

export const { logout, setTokens, setApprovesCheckCount } = userSlice.actions
export default userSlice.reducer
