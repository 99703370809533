import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getTasks = createAsyncThunk('tasks/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/tasks/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveTask = createAsyncThunk('task/save', async (task, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.put(`/api/tasks/${task.id}/`, task)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const createTask = createAsyncThunk('task/create', async (task, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.post('/api/tasks/', task)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const deleteTask = createAsyncThunk('task/delete', async (task, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.delete(`/api/tasks/${task.id}/`)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})
