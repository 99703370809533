import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getWoodDoors = createAsyncThunk('woodDoors/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/wooddoors/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveWoodDoor = createAsyncThunk(
  'woodDoor/save',
  async (woodDoor, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.put(`/api/wooddoors/${woodDoor.id}/`, woodDoor)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const createWoodDoor = createAsyncThunk(
  'woodDoor/create',
  async (woodDoor, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.post('/api/wooddoors/', woodDoor)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteWoodDoor = createAsyncThunk(
  'woodDoor/delete',
  async (woodDoor, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.delete(`/api/wooddoors/${woodDoor.id}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
