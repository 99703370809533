import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getWorkTzs = createAsyncThunk('worktzs/get', async (type, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get(`/api/${type}/`)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveWorkTz = createAsyncThunk('worktz/save', async (w, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.put(`/api/${w.type}/${w.tz.id}/`, w.tz)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const createWorkTz = createAsyncThunk('worktz/create', async (w, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.post(`/api/${w.type}/`, w.tz)
    return data
  } catch (error) {
    console.log(error)
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const testWorkTz = (...args) => {
  console.log(args)
}

export const deleteWorkTz = createAsyncThunk('worktz/delete', async (w, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.delete(`/api/${w.type}/${w.id}/`)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})
