import { createSlice } from '@reduxjs/toolkit'
import { getTasks, saveTask, createTask, deleteTask } from './taskActions'

const initialState = {
  tasks: [],
  loading: false,
  error: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedTask: {},
  deleting: false,
  deleted: false,
  deletingError: false,
  loaded: false,
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedTask = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
      state.loaded = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getTasks.fulfilled, (state, { payload }) => {
        state.loading = false
        state.tasks = payload
        state.loaded = true
      })
      .addCase(getTasks.pending, (state) => {
        state.loading = true
        state.error = null
        state.loaded = false
      })
      .addCase(saveTask.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(saveTask.fulfilled, (state, { payload }) => {
        state.savingError = null
        //state.tasks[state.tasks.findIndex((p) => p.id === payload.id)] = payload
        state.savedTask = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveTask.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createTask.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createTask.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedTask = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createTask.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteTask.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteTask.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteTask.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
      .addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
          state.loaded = false
        },
      )
  },
})
export const { clear } = tasksSlice.actions
export default tasksSlice.reducer
