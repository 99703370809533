import { createSlice } from '@reduxjs/toolkit'
import { getDemands, saveDemand, createDemand, deleteDemand } from './demandActions'

const initialState = {
  demands: [],
  loading: false,
  error: false,
  loaded: false,
  loadingError: false,
  savingError: false,
  saving: false,
  saved: false,
  savedDemand: {},
  deleting: false,
  deleted: false,
  deletingError: false,
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const demandSlice = createSlice({
  name: 'demands',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.savingError = false
      state.loadingError = false
      state.savedDemand = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
      state.loaded = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getDemands.fulfilled, (state, { payload }) => {
        state.loading = false
        state.demands = payload
        state.loaded = true
      })
      .addCase(getDemands.pending, (state) => {
        state.loading = true
        state.error = null
        state.loaded = false
      })
      .addCase(saveDemand.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(saveDemand.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.demands[state.demands.findIndex((p) => p.id === payload.id)] = payload
        state.savedDemand = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveDemand.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createDemand.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createDemand.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedDemand = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createDemand.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteDemand.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteDemand.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteDemand.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
      .addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
        },
      )
  },
})
export const { clear } = demandSlice.actions
export default demandSlice.reducer
