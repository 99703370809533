// ProtectedRoute.js
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'

const ProtectedRoute = () => {
  const { authTokens } = useSelector((state) => state.user)
  const navigate = useNavigate()

  useEffect(() => {
    if (!authTokens) {
      navigate('/login')
    }
  })

  return <Outlet />
}
export default ProtectedRoute
